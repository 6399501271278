import { motion } from 'framer-motion'
import { cx } from '@/utils/strings'

type CollapsibleProps = {
  className?: string
  children: JSX.Element
  isOpen?: boolean
}

export default function Collapsible({ className, children, isOpen }: CollapsibleProps) {
  return (
    <motion.div
      className={cx(className, 'overflow-hidden')}
      initial={false}
      exit="collapsed"
      animate={isOpen ? 'open' : 'collapsed'}
      variants={{
        open: {
          opacity: 1,
          height: 'auto',
          transition: {
            ease: 'anticipate',
            height: { duration: 0.3 },
            opacity: { duration: 0.2 },
          },
        },
        collapsed: {
          opacity: 0,
          height: 0,
          transition: {
            ease: 'anticipate',
            height: { duration: 0.2 },
            opacity: { duration: 0.1 },
          },
        },
      }}
    >
      {children}
    </motion.div>
  )
}
