import { useEffect, useState } from 'react'
import { useIsClient, useLocalStorage } from 'usehooks-ts'
import type { Entry } from 'contentful'
import type { TypeHeaderBannerSkeleton } from '@/types/ctf'
import RichText from '@/components/ui/rich-text'
export type BannerProps = {
  id: string
  content: any
}

export function Banner({ id, content }: BannerProps) {
  const [isBannerClosed, setIsBannerClosed] = useLocalStorage(`bw-banner-closed-${id}`, typeof window === 'undefined')

  return (
    !isBannerClosed && (
      <div className="relative bg-indigoBlue text-white text-center align-middle pt-2 pb-3 [:not(:last-of-type)]:border-b border-deepBlue">
        <div className="tracking-wide leading-4 font-medium ml-5 mr-8 lg:mx-12 flex items-center justify-center [&_p]:text-smaller lg:[&_p]:text-base [&_p]:mb-0 [&_a]:!text-tealBlue text-left lg:text-center">
          <RichText body={content.content[0]} />
        </div>
        <button
          type="button"
          className="cursor-pointer rounded absolute top-1 right-1 w-6 h-6 md:top-1/2 md:-translate-y-1/2 md:right-4 md:w-8 md:h-8 hover:text-gray-300 hover:bg-gray-700"
          onClick={() => {
            setIsBannerClosed(true)
          }}
        >
          <span className="font-bwi text-sm lg:text-base" data-name="bwi-close">
            &#xe93d;
          </span>
        </button>
      </div>
    )
  )
}

export type HeaderBannerProps = {
  isHelpSite?: boolean
  banners?: Entry<TypeHeaderBannerSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>[]
}

export default function HeaderBanner(props) {
  const { banners, isHelpSite = false } = props
  const isClient = useIsClient()
  const [path, setPath] = useState('')

  useEffect(() => {
    const { pathname } = window.location
    if (pathname) {
      !pathname.endsWith('/') ? setPath(pathname + '/') : setPath(pathname)
    }
  }, [])

  const testUrl = (page) => {
    let newUrl = new URL(page.fields?.url, 'https://bitwarden.com').pathname
    if (!newUrl.endsWith('/')) {
      newUrl += '/'
    }
    return newUrl
  }

  const bannersNotToShow =
    banners?.filter(
      ({ fields: { showOnHelp, pagesNotToDisplayOn = [] } }) =>
        (!showOnHelp ? !isHelpSite : true) &&
        Array.isArray(pagesNotToDisplayOn) &&
        pagesNotToDisplayOn.some((page) => path === testUrl(page))
    ) || []

  const bannersShow =
    banners?.filter(
      ({ fields: { showOnHelp, pagesToDisplay = [] } }) =>
        (showOnHelp ? !isHelpSite : true) &&
        Array.isArray(pagesToDisplay) &&
        pagesToDisplay.some((page) => path === testUrl(page))
    ) || []

  const bannersEveryPage =
    banners?.filter((banner) => {
      if (!banner.fields.showOnHelp && !isHelpSite && !banner.fields.pagesToDisplay) {
        return banner
      }
    }) || []

  const showBanners = [...bannersShow, ...bannersEveryPage]

  // create a new array from showBanners and remove any banners that are in the bannersNotToShow
  bannersNotToShow.map((dontShowBanner) => {
    showBanners.map((show, index) => {
      if (dontShowBanner.sys.id === show.sys.id) {
        showBanners.splice(index, 1)
      }
    })
  }) || []

  return (
    isClient &&
    showBanners?.map((banner: Entry<TypeHeaderBannerSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>) => (
      <Banner id={banner.sys.id} key={banner.sys.id} {...banner.fields} />
    ))
  )
}
