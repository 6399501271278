import { type RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import { useMemo } from 'react'
import { useRefinementList, type UseRefinementListProps, useSearchBox } from 'react-instantsearch'
import { cx } from '@/utils/strings'

export type SearchType = '' | 'pages' | 'help' | 'blog' | 'resources' | 'learning' | 'events' | 'tips' | 'newsfeed'
export const searchTypes: SearchType[] = [
  'pages',
  'help',
  'blog',
  'resources',
  'learning',
  'events',
  'tips',
  'newsfeed',
]
export const setType = (type: SearchType, items: RefinementListItem[], refine: any) => {
  const newType = searchTypes.includes(type) ? type : ''
  items.forEach((item) => {
    if (item.isRefined && item.value !== newType) refine(item.value)
    else if (!item.isRefined && item.value === newType) refine(newType)
  })
}

export default function RefinementList(props: UseRefinementListProps) {
  const { items, refine } = useRefinementList(props)
  const { query } = useSearchBox()

  const types = useMemo(
    () => [
      {
        count: items.reduce((sum, item) => sum + item.count, 0),
        value: '',
        label: 'all results',
        isRefined: !items.some((item) => item.isRefined),
      },
      ...searchTypes.map(
        (type) =>
          items.find((item) => item.value === type) || {
            count: 0,
            value: type,
            label: type,
            isRefined: false,
          }
      ),
    ],
    [items]
  )

  return (
    <ul className="list-none flex justify-center flex-wrap pt-4 sm:pb-4">
      {query.length > 0 &&
        types?.[0]?.count > 0 &&
        types.map((item, index) => (
          <li key={index}>
            <button
              type="button"
              disabled={item.count === 0}
              className={cx(
                `mx-2 px-2 mb-2 border text-gray-600 rounded-t
                  sm:border-0 sm:border-b-2
                  hover:bg-gray-100
                  disabled:pointer-events-none`,
                item.isRefined && `font-bold text-primaryBlue bg-gray-200! border-primaryBlue border-2`,
                item.count === 0 && `text-gray-300`
              )}
              onClick={() => setType(item.value as SearchType, items, refine)}
            >
              {item.label} <small className="text-gray-400">({item.count})</small>
            </button>
          </li>
        ))}
    </ul>
  )
}
