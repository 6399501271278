export const prefixGroup = (prefix: string, classNames: string) =>
  classNames
    .split(' ')
    .map((className) => `${prefix}:${className}`)
    .join(' ')

export const sm = (classNames: string) => prefixGroup('sm', classNames)
export const md = (classNames: string) => prefixGroup('md', classNames)
export const lg = (classNames: string) => prefixGroup('lg', classNames)
export const xl = (classNames: string) => prefixGroup('xl', classNames)
export const TwoXl = (classNames: string) => prefixGroup('2xl', classNames)
export const hover = (classNames: string) => prefixGroup('hover', classNames)
export const focus = (classNames: string) => prefixGroup('focus', classNames)
export const active = (classNames: string) => prefixGroup('active', classNames)
export const groupHover = (classNames: string) => prefixGroup('group-hover', classNames)
export const focusWithin = (classNames: string) => prefixGroup('focus-within', classNames)
export const first = (classNames: string) => prefixGroup('first', classNames)
export const last = (classNames: string) => prefixGroup('last', classNames)
export const odd = (classNames: string) => prefixGroup('odd', classNames)
export const even = (classNames: string) => prefixGroup('even', classNames)
export const visited = (classNames: string) => prefixGroup('visited', classNames)
export const checked = (classNames: string) => prefixGroup('checked', classNames)
export const disabled = (classNames: string) => prefixGroup('disabled', classNames)
