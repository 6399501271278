import { useCallback, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useScrollLock, useOnClickOutside } from 'usehooks-ts'

import { Portal } from '@headlessui/react'
import { cx } from '@/utils/strings.ts'

type NavContainerProps = {
  className?: string
  /** The anchor position of the drawer on the page */
  position: 'left' | 'right'
  isDrawerOpen?: boolean
  /** Display only the drawer */
  isDrawerOnly?: boolean
  /** Breakpoint to hide the drawer */
  drawerBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  /** Header ref used to offset the position of the drawer */
  headerRef?: React.RefObject<HTMLElement>
  children: React.ReactNode
  onRequestClose?: () => void
  showOverlay?: boolean
  fullWidth?: boolean
}

export default function NavContainer({
  className,
  position = 'left',
  isDrawerOpen = false,
  isDrawerOnly = false,
  drawerBreakpoint = 'md',
  children,
  onRequestClose,
  headerRef,
  showOverlay = true,
  fullWidth = false,
}: NavContainerProps) {
  const ref = useRef<HTMLDivElement>(null)

  const [offset, setOffset] = useState(0)
  const calculateOffset = useCallback(() => {
    if (headerRef?.current) {
      const rect = headerRef.current.getBoundingClientRect()
      setOffset(rect.bottom)
    }
  }, [headerRef?.current])

  // Get the header height to set the offset for the drawer
  useEffect(() => {
    calculateOffset()
  }, [headerRef?.current])

  // Lock the body when the drawer is open
  const { lock, unlock } = useScrollLock({
    autoLock: false,
  })
  useEffect(() => {
    calculateOffset()
    isDrawerOpen ? lock() : unlock()
  }, [isDrawerOpen])

  useOnClickOutside(
    ref,
    useCallback(() => {
      onRequestClose?.()
    }, [])
  )

  return (
    <>
      {showOverlay && (
        <Portal>
          <motion.div
            className={cx(
              'fixed opacity-0 inset-0 bg-black/60',
              isDrawerOpen ? ` z-10` : ` -z-50`,
              drawerBreakpoint === 'sm' && `sm:hidden`,
              drawerBreakpoint === 'md' && `md:hidden`,
              drawerBreakpoint === 'lg' && `lg:hidden`,
              drawerBreakpoint === 'xl' && `xl:hidden`,
              drawerBreakpoint === '2xl' && `2xl:hidden`
            )}
            animate={{ opacity: isDrawerOpen ? 1 : 0 }}
            aria-hidden="true"
          />
        </Portal>
      )}
      <motion.div
        style={
          {
            '--nav-container-drawer-offset': `${offset}px`,
            '--nav-container-drawer-height': `calc(100vh - ${offset}px)`,
          } as any
        }
        className={cx(
          `fixed top-0 bottom-0 max-w-full bg-white z-20 h-full flex-none overflow-auto pb-12`,
          `top-[--nav-container-drawer-offset] h-[-nav-container-drawer-height]`,
          !isDrawerOnly && drawerBreakpoint === 'sm' && `sm:block sm:h-auto sm:py-4 sm:!translate-x-0 sm:top-auto`,
          !isDrawerOnly && drawerBreakpoint === 'md' && `md:block md:h-auto md:py-4 md:!translate-x-0 md:top-auto`,
          !isDrawerOnly && drawerBreakpoint === 'lg' && `lg:block lg:h-auto lg:py-4 lg:!translate-x-0 lg:top-auto`,
          !isDrawerOnly && drawerBreakpoint === 'xl' && `xl:block xl:h-auto xl:py-4 xl:!translate-x-0 xl:top-auto`,
          !isDrawerOnly &&
            drawerBreakpoint === '2xl' &&
            `2xl:block 2xl:h-auto 2xl:py-4 2xl:!translate-x-0 2xl:top-auto`,
          position === 'left' && `left-0 right-auto -translate-x-full`,
          position === 'right' && `right-0 left-auto translate-x-full`,
          fullWidth ? `w-[100vw]` : `w-[20rem]`,
          className
        )}
        initial="closed"
        variants={{
          open: { translateX: 0 },
          closed: { translateX: position === 'left' ? '-100%' : '100%' },
        }}
        animate={isDrawerOpen ? 'open' : 'closed'}
        transition={{ duration: 0.2, ease: 'easeOut' }}
        ref={ref}
      >
        {children}
      </motion.div>
    </>
  )
}
