import type { Entry } from 'contentful'
/* eslint-disable react/jsx-props-no-spreading */
import Link from '@/components/link-component'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'
import { hover, focus, groupHover } from '@/utils/tailwind'
import type { TypeComponentIconSkeleton } from '@/types/ctf'

export const buttonStyles = {
  btnArrow: 'Arrow Link',
  btnOutlined: 'Button: Outlined',
  btnSolid: 'Button: Solid',
  btnPlain: 'Plain Link',
}

const { btnSolid, btnOutlined, btnArrow, btnPlain } = buttonStyles
const { primaryBlue, lightGray, indigoBlue, white, transparent } = colors

export type DisplayType = 'Inline' | 'Block'
export type AlignType = 'Left' | 'Center'
export type SizeType = 'Base' | 'Smaller' | 'Larger'
export type VariantType = typeof btnArrow | typeof btnOutlined | typeof btnSolid | typeof btnPlain

export type CtaLinkProps = {
  className?: string
  asButton?: boolean
  to?: string
  children?: any
  display?: DisplayType | string
  size?: SizeType
  align?: AlignType
  style?: VariantType
  backgroundColor?: string
  icon?: Entry<TypeComponentIconSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
  iconPosition?: string
  scrollToOffset?: number
  color?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  plausibleEventName?: string
  css?: string
  internalName?: string
}

export default function CtaLink({
  to,
  asButton = false,
  children,
  display = 'Inline',
  size = 'Base',
  style = btnArrow,
  backgroundColor = white,
  align = 'Left',
  icon = undefined,
  iconPosition = 'Left',
  css,
  internalName = 'no name',
  className: _className,
  plausibleEventName,
  ...other
}: CtaLinkProps): JSX.Element {
  const hoverFocusStyle = 'bg-deepBlue text-white border-deepBlue'
  const darkModeHoverStyle =
    'dark:text-gray-800 dark:border-white dark:bg-white dark:hover:bg-transparent dark:hover:text-white dark:hover:border-white'
  const btnHoverStyle = [
    groupHover(hoverFocusStyle),
    hover(hoverFocusStyle),
    focus(`outline-none ${hoverFocusStyle}`),
  ].join(' ')
  let className = _className || ''
  if (plausibleEventName?.trim()) {
    className += ` ${plausibleEventName}`
  }

  const buttonStyle = cx(
    'transition-[background-color, color, box-shadow, border-color] ease-in-out duration-300',
    align === 'Center' && 'mx-auto',
    size === 'Smaller' && 'px-4 py-2 text-base h-[46px]',
    size === 'Base' && 'px-6 py-3 text-lg',
    size === 'Larger' && 'px-8 py-4 text-[21px]',
    display === 'Inline' && 'inline-block',
    display === 'Block' && 'table',
    (btnArrow === style || btnPlain === style) && '!px-0 hover:text-indigoBlue',
    (style === btnOutlined || style === btnSolid) && 'border-2 border-solid rounded-full text-center',
    btnArrow === style && [primaryBlue, indigoBlue].includes(backgroundColor) && 'text-white hover:!text-gray-300',
    btnArrow === style && [white, lightGray].includes(backgroundColor) && 'text-primaryBlue',
    btnPlain === style && 'underline py-0',
    btnSolid === style &&
      [indigoBlue, primaryBlue].includes(backgroundColor) &&
      `bg-white border-white text-primaryBlue ${btnHoverStyle} ${darkModeHoverStyle}`,
    btnSolid === style &&
      [lightGray, white, transparent].includes(backgroundColor) &&
      `bg-primaryBlue border-primaryBlue text-white ${btnHoverStyle} ${darkModeHoverStyle}`,
    btnOutlined === style &&
      [indigoBlue, primaryBlue].includes(backgroundColor) &&
      `border-white text-white ${btnHoverStyle} dark:border-white dark:text-white`,
    btnOutlined === style &&
      [white, lightGray, transparent].includes(backgroundColor) &&
      `border-primaryBlue text-primaryBlue ${btnHoverStyle} dark:border-white dark:text-white dark:hover:bg-white dark:hover:text-gray-800 dark:hover:border-white`,
    css,
    className.trim()
  )

  const renderBtnText = () => (
    <span className="flex items-center">
      {icon && iconPosition === 'Left' && (
        <span className="font-bwi mr-2 !text-[16px]">{String.fromCodePoint(parseInt(icon.fields.unicode, 16))}</span>
      )}
      {children}
      {btnArrow === style && <span className="font-bwi ml-2 !text-[16px]">&#xe91b;</span>}
      {icon && iconPosition === 'Right' && (
        <span className="font-bwi ml-2 !text-[16px]">{String.fromCodePoint(parseInt(icon.fields.unicode, 16))}</span>
      )}
    </span>
  )
  return asButton ? (
    <button type="button" aria-label={to && `go to ${to}`} data-testid="cta-link" className={buttonStyle} {...other}>
      {renderBtnText()}
    </button>
  ) : (
    <Link aria-label={`go to ${to}`} to={to} className={buttonStyle} {...other} data-testid="cta-link">
      {renderBtnText()}
    </Link>
  )
}
