import { cx } from '@/utils/strings'

type ContainerProps = {
  // eslint-disable-next-line react/require-default-props
  className?: string
  children: JSX.Element | JSX.Element[]
}

export default function Container({ className, children }: ContainerProps) {
  return <div className={cx('max-w-8xl mx-auto px-5', className)}>{children}</div>
}
