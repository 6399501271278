const Routes = {
  about: '/about/',
  becomePartner: '/partners/become-a-partner/',
  bitwardenReviews: '/bitwarden-reviews/',
  blog: '/blog/',
  business: '/products/business/',
  businessSupport: '/products/business-support/',
  careers: '/careers/',
  community: 'https://community.bitwarden.com/',
  compliance: '/compliance/',
  contact: '/contact/',
  contactSales: '/contact-sales/',
  contributorGuidelines: 'https://contributing.bitwarden.com/',
  cookies: '/privacy/cookies/',
  dealRegistration: '/partners/deal-registration/',
  discovery: '/discovery/',
  download: '/download/',
  events: '/events/',
  feed: '/blog/feed.xml',
  getStarted: 'https://vault.bitwarden.com/#/register?layout=default',
  getStartedEnterprise: 'https://vault.bitwarden.com/#/register?org=enterprise&layout=enterprise2',
  getStartedEnterpriseCnet: 'https://vault.bitwarden.com/#/register?org=enterprise&layout=cnetcmpgnent',
  getStartedFreeOrg: 'https://vault.bitwarden.com/#/register?org=free&layout=default',
  getStartedFamilies: 'https://vault.bitwarden.com/#/register?org=families&layout=default',
  getStartedTeams: 'https://vault.bitwarden.com/#/register?org=teams&layout=teams1',
  getStartedTeamsCnet: 'https://vault.bitwarden.com/#/register?org=teams&layout=cnetcmpgnteams',
  getStartedPremium: 'https://vault.bitwarden.com/#/register?premium=purchase',
  getStartedPremiumPurchase: 'https://vault.bitwarden.com/#/register?premium=purchase&layout=default',
  getStartedPremiumCnet: 'https://vault.bitwarden.com/#/register?org=individual&layout=cnetcmpgnind',
  trialEnterprise: 'https://vault.bitwarden.com/#/trial?org=enterprise&layout=enterprise2',
  trialEnterpriseCnet: 'https://vault.bitwarden.com/#/trial?org=enterprise&layout=cnetcmpgnent',
  trialFreeOrg: 'https://vault.bitwarden.com/#/trial?org=free&layout=default',
  trialTeams: 'https://vault.bitwarden.com/#/trial?org=teams&layout=teams1',
  trialTeamsCnet: 'https://vault.bitwarden.com/#/trial?org=teams&layout=cnetcmpgnteams',
  trialFamilies: 'https://vault.bitwarden.com/#/trial?org=families',
  help: '/help/',
  index: '/',
  learning: '/learning/',
  login: 'https://vault.bitwarden.com/#/',
  msp: '/msp/',
  newsfeed: '/newsfeed/',
  newsletterSubscribe: '/newsletter-subscribe/',
  opensource: '/open-source/',
  partners: '/partners/',
  passwordGenerator: '/password-generator/',
  passwordTester: '/password-strength/',
  passkeys: '/passwordless-passkeys/',
  personal: '/products/personal/',
  pressRoom: '/resources/press-room/',
  pricing: '/pricing/business/',
  pricingBusiness: '/pricing/business/',
  pricingPersonal: '/pricing/',
  privacy: '/privacy/',
  products: '/products/',
  productsBusiness: '/products/business/',
  productsPersonal: '/products/personal/',
  register: '/#/register/',
  resources: '/resources/',
  send: '/products/send/',
  sitemap: '/sitemap/',
  spotlight: '/media-spotlight/',
  surveyRoom: '/resources/the-survey-room/',
  terms: '/terms/',
  usernameGenerator: '/username-generator/',
  userReviews: '/user-reviews/',
  weekly: '/weekly/',
}

export default Routes
