import { CloudinaryImage } from '@cloudinary/url-gen'

import { Head, usePage } from '@inertiajs/react'
import { TypeSeoSkeleton } from '@/types/ctf'

type MetaProps = {
  seo: TypeSeoSkeleton
  breadcrumb?: { name: string; path: string }[]
}

export default function Meta({ seo, breadcrumb = [] }: MetaProps) {
  const { PUBLIC_APP_URL } = usePage().props.env
  const { url } = usePage()

  let fullTitle = seo?.fields?.title || ''
  if (fullTitle) {
    fullTitle += ' | '
  }
  fullTitle += 'Bitwarden'

  const cldImg = new CloudinaryImage(
    seo?.fields?.ogImage?.fields?.file?.url?.replace('//images.ctfassets.net/', 'ctf/')?.replace('//images.contentful.com/', 'ctf/') ||
      'ctf/7rncvj1f8mw7/7MxCY8VttLPQLbByiotlug/912d4b50c0ddccbb93478c0d1fad3fe8/bitwarden.com.png',
    {
      cloudName: 'bw-com',
    }
  )

    // todo: not working as is.  need to change this field to use `Component: Link`
    const canonicalPath = seo?.fields?.canonical?.entry?.fields?.path

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta name="description" content={seo?.fields?.description || ''} />
      <meta name="keywords" content={seo?.fields?.keywords?.join(', ') || ''} />

      {seo?.fields?.noIndex && <meta name="robots" content="noindex" />}
      {seo?.fields?.noFollow && <meta name="robots" content="nofollow" />}

      {canonicalPath && <link rel="canonical" href={canonicalPath} />}

      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={seo?.fields?.description || ''} />
      <meta property="og:image" content={cldImg.toURL()} />
      <meta property="og:url" content={canonicalPath || PUBLIC_APP_URL + url} />

      <meta property="twitter:site" content="@bitwarden" />
      <meta property="twitter:domain" content="bitwarden.com" />
      <meta property="twitter:url" content={cldImg.toURL()} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={seo?.fields?.description} />
      <meta name="twitter:image" content={cldImg.toURL()} />
      {breadcrumb?.length > 1 && (
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type" : "ListItem",
                  "position": "1",
                  "name": "${breadcrumb[0].name}",
                  "item": "${PUBLIC_APP_URL}${breadcrumb[0].path}"
                },
                {
                  "@type": "ListItem",
                  "position": "2",
                  "name": "${breadcrumb[1].name}"
                }
              ]
            }`}
        </script>
      )}
    </Head>
  )
}
