import { useCallback, useEffect, useRef } from 'react'
import { usePage } from '@inertiajs/react'
import BwiSearch from '@/icons/bwi/search.svg'
import { searchTypes } from './refinement-list'

export default function NavIcon(className) {
  const ref = useRef<HTMLButtonElement>(null)
  const page = usePage()
  const pathname = page.url
  const gotoSearch = useCallback(() => {
    const type = searchTypes.find((thisType) => pathname.startsWith(`/${thisType}/`))

    if (type) {
      window.location.assign(`/search/?${new URLSearchParams({ type })}`)
    } else window.location.assign('/search/')
  }, [pathname])

  useEffect(() => {
    if (ref.current)
      ref.current.setAttribute(
        'title',
        `${'Search'} (${
          typeof navigator !== 'undefined' && /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl'
        } + K)`
      )
    const onKeyDown = (event: KeyboardEvent) => {
      const { pathname } = window.location

      if (event?.key?.toLowerCase() === 'k' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault()
        gotoSearch()
      } else if (event?.key === 'Escape' && pathname.endsWith('/search/')) {
        event.preventDefault()
        // navigate(-1)
      }
    }

    window.addEventListener('keydown', onKeyDown)

    return (): void => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <button
      type="button"
      ref={ref}
      onClick={gotoSearch}
      className={`w-10 h-10 p-1 lg:w-12 lg:h-12 lg:p-3 hover:text-gray-300 text-white ${className}`}
    >
      <BwiSearch />
    </button>
  )
}
