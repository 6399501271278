import React, { useState } from 'react'
import ArrowRight from '@/icons/bwi/angle-right.svg'
import Collapsible from '@/components/ui/collapsible'
import { cx } from '@/utils/strings'

type DisclosureProps = {
  heading: JSX.Element
  id?: string
  className?: string
  children: JSX.Element
  defaultOpen?: boolean
  onClick?: () => void
  ArrowIcon?: (isOpen: boolean) => JSX.Element
}

export default function Disclosure({
  id,
  heading,
  children,
  className,
  defaultOpen,
  onClick,
  ArrowIcon,
}: DisclosureProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  return (
    <>
      <button
        type="button"
        className={cx('flex items-center w-full text-left rounded-full py-1 px-2 hover:bg-gray-200', className)}
        id={id}
        onClick={() => {
          onClick?.()
          setIsOpen(!isOpen)
        }}
      >
        {heading}
        {ArrowIcon?.(isOpen) || (
          <span className={cx('w-4 h-4 ml-1 transition-transform flex-none', isOpen && 'rotate-90')}>
            <ArrowRight />
          </span>
        )}
      </button>
      <Collapsible className="text-sm" isOpen={isOpen}>
        {children}
      </Collapsible>
    </>
  )
}
