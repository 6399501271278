import { useRef, useEffect } from 'react'

import LogoHorizontal from '@/icons/logo-horizontal.svg'
import Container from '@/components/container'
import LinkComponent from '@/components/link-component'
import SelectLocale from '@/components/select-locale'
import CtaLink from '@/components/ui/cta-link'
import RichText from '@/components/ui/rich-text'
import { cx } from '@/utils/strings'
import routes from '@/constants/routes'

export default function Footer({ footerNav, showLinkSections = true, otherLocales = [] }) {
  if (!footerNav) return <></>
  const footerBottomLinksRef = useRef(null)
  // const { currentContentfulCode, lang } = useLocale()

  useEffect(() => {
    const osanoLink = footerBottomLinksRef.current.querySelector('a[href$="#osano"]')

    if (osanoLink) {
      osanoLink.addEventListener('click', (event) => {
        if (window.Osano && window.Osano.cm) {
          event.preventDefault()
          window.Osano.cm.locale = 'en'
          window.Osano.cm.showDrawer()
        }
      })
    }
  }, [])

  const {
    fields: { linkSections, bottomText },
  } = footerNav

  const footerIconStyle = 'text-base '
  const footerLinkStyle = 'text-indigoBlue hover:text-primaryBlue dark:text-gray-300 dark:hover:text-gray-400'

  const renderBottomLinks = () => (
    <div
      ref={footerBottomLinksRef}
      className="[&_p]:text-base mb-0 [&>p>a]:no-underline [&>p>a]:ml-2 [&>p>a]:text-indigoBlue [&>p>a:hover]:text-primaryBlue dark:[&>p>a]:text-gray-300 dark:[&>p>a:hover]:text-gray-400"
    >
      <RichText body={bottomText.fields.body} />
    </div>
  )

  return (
    <footer className="bg-gray-100 text-indigoBlue py-10 border-t border-gray-200  dark:text-white dark:bg-gray-800 dark:border-gray-800 relative">
      <Container>
        {showLinkSections ? (
          <div className="flex flex-col gap-10">
            <nav className="grid gap-8 sm:grid-cols-2 md:grid-cols-4">
              {linkSections.map((section, i) => (
                <div key={i}>
                  <h4 className="text-2xl mb-4">{section.fields.heading}</h4>
                  <ul className="flex flex-col gap-3 list-none">
                    {section.fields.links.map((link, i) => (
                      <li key={link.sys.id}>
                        <CtaLink
                          to={link.fields.link.fields.url}
                          {...link.fields}
                          className={cx('no-underline !text-base !py-0 inline-block', footerLinkStyle)}
                        >
                          {link.fields.label}
                        </CtaLink>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
            <hr className="dark:border-gray-600" />
            <div className="flex flex-col gap-6 justify-between items-center lg:flex-row [&>p]:text-base">
              {renderBottomLinks()}
              <div className="flex gap-4 flex-wrap">
                <LinkComponent to="https://twitter.com/bitwarden" className={cx(footerIconStyle, footerLinkStyle)}>
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-twitter">
                    &#xe9a5;
                  </span>
                </LinkComponent>
                <LinkComponent to="https://reddit.com/r/bitwarden" className={cx(footerIconStyle, footerLinkStyle)}>
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-reddit">
                    &#xe95a;
                  </span>
                </LinkComponent>
                <LinkComponent to="https://community.bitwarden.com/" className={cx(footerIconStyle, footerLinkStyle)}>
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-community">
                    &#xe994;
                  </span>
                </LinkComponent>
                <LinkComponent to="https://github.com/bitwarden" className={cx(footerIconStyle, footerLinkStyle)}>
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-github">
                    &#xe950;
                  </span>
                </LinkComponent>
                <LinkComponent
                  to="https://www.youtube.com/channel/UCId9a_jQqvJre0_dE2lE_Rw"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-youtube">
                    &#xe966;
                  </span>
                </LinkComponent>
                <LinkComponent
                  to="https://www.linkedin.com/company/bitwarden1"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-linkedin">
                    &#xe955;
                  </span>
                </LinkComponent>
                <LinkComponent
                  to="https://www.facebook.com/bitwarden/"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-facebook">
                    &#xe94d;
                  </span>
                </LinkComponent>
                <LinkComponent
                  to="https://www.instagram.com/bitwarden/"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-instagram">
                    &#xe999;
                  </span>
                </LinkComponent>
                <LinkComponent
                  rel="me"
                  to="https://fosstodon.org/@bitwarden"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-mastodon">
                    &#xe995;
                  </span>
                </LinkComponent>
                <LinkComponent
                  to="https://www.twitch.tv/bitwardenlive"
                  className={cx(footerIconStyle, footerLinkStyle)}
                >
                  <span className="font-bwi text-[1.4rem]" data-name="bwi-twitch">
                    &#xe993;
                  </span>
                </LinkComponent>
              </div>
              <div>
                <SelectLocale otherLocales={otherLocales} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="w-40">
              <LinkComponent to={routes.index}>
                <LogoHorizontal className="text-primaryBlue" />
              </LinkComponent>
            </div>
            {renderBottomLinks()}
          </div>
        )}
      </Container>
    </footer>
  )
}
