import { cx } from '@/utils/strings'
import { type MotionProps, motion } from 'framer-motion'

type StaggerListProps = {
  children: React.ReactNode
  className?: string
  css?: string
  isVisible?: boolean
} & MotionProps

type StaggerListItemProps = {
  children: React.ReactNode
  className?: string
  css?: string
} & MotionProps

export function StaggerListItem({ children, ...rest }: StaggerListItemProps) {
  return (
    <motion.li
      variants={{
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 },
      }}
      {...rest}
    >
      {children}
    </motion.li>
  )
}

export default function StaggerList({ children, className, css, isVisible = false, ...rest }: StaggerListProps) {
  return (
    <motion.ul
      className={cx(className, css)}
      variants={{
        hidden: {
          transition: { staggerChildren: 0.01, staggerDirection: -1 },
        },
        visible: {
          transition: { staggerChildren: 0.03, delayChildren: 0.1 },
        },
      }}
      animate={isVisible ? 'visible' : 'hidden'}
      {...rest}
    >
      {children}
    </motion.ul>
  )
}
