import CtaLink from './ui/cta-link'
import { cx } from '@/utils/strings'
import { locales, localizedPath, useLocale } from '@/utils/locale.ts'
import { router, usePage } from '@inertiajs/react'

export default function SelectLocale({ otherLocales = [] }): JSX.Element {
  const { url } = usePage()
  const { currentLocale } = useLocale(url)

  return (
    <div>
      <label htmlFor="footer-language" className="absolute left-[-9000px]">
        Language
      </label>
      <div className="relative">
        <select
          className="w-auto h-10 pl-4 pr-8 bg-transparent border border-gray-200 rounded-4xl appearance-none outline-transparent
            focus-visible:outline-none focus-visible:border focus-visible:border-primaryBlue focus-visible:shadow-[0_0_0_1px] focus-visible:shadow-primaryBlue cursor-pointer hover:bg-gray-100 hover:border-gray-300 dark:hover:bg-transparent"
          id="footer-language"
          defaultValue={currentLocale}
          onChange={(event) => {
            router.visit(localizedPath(url, event.target.value))
          }}
        >
          {locales.map((locale) => (
            <option key={locale.slug} value={locale.slug} style={{ backgroundColor: 'indigoBlue' }}>
              {locale.label}
            </option>
          ))}
        </select>
        <span className="font-bwi block absolute top-0 right-4 pointer-events-none text-[0.625rem] leading-[2.5rem]">
          &#xe92d;
        </span>
      </div>
    </div>
  )
}
